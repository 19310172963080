/* ==========================================================================
   Tools
     > Banner UI
   ========================================================================== */

@mixin t-banner--ui {
  > * {
    margin: 16px 0 0;
  }

  h1 {
    margin-top: 8px;
    font-weight: 500;

    @include bp-medium-large {
      max-width: 900px;
    }

    [data-component__theme='dark'] & {
      color: #fff;
    }
  }

  p {
    @include t-heading--h3;
    font-weight: 400;
    width: 86%;
    margin-bottom: 0;
    line-height: 1.5;

    @include bp-max-medium {
      font-size: 22px;
    }

    @include bp-medium {
      width: 66%;
    }
    @include bp-medium {
      font-size: 32px;
    }

    @include bp-largest {
      width: auto;
      max-width: 840px;
    }
  }

  h1 ~ p {
    @include bp-full {
      margin-bottom: 30px;
    }
  }

  .c-btn {
    margin-top: 32px;
    margin-right: 16px;

    @include bp-full {
      margin-top: 40px;
    }
  }

  .c-btns {
    .c-btn {
      margin: 0;
    }
  }
}
