/* ==========================================================================
   Tools
     > Headings
   ========================================================================== */

// Base

@mixin t-heading--base {
  font-family: var(--font--headings);
  font-weight: 400;
  color: var(--color--text);

  &[id] {
    scroll-margin-top: calc(var(--header__height) + 32px);
  }
}

// h1

@mixin t-heading--h1 {
  font-size: unquote('clamp(2.4rem, 1.6441rem + 4.7244vw, 4.8rem)');
  line-height: 1;
  margin-bottom: 40px;
  letter-spacing: -1.28px;

  @include bp-medium {
    letter-spacing: -3.2px;
  }
}

// h2

@mixin t-heading--h2 {
  font-size: 34px;
  line-height: 42px;
  margin-bottom: calc(32px - 8px);

  @include bp-medium {
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -1.134px;
  }
}

// h3

@mixin t-heading--h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.224px;
  margin-bottom: 16px;
}

// h4

@mixin t-heading--h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 16px;
}

// h5

@mixin t-heading--h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0px;
  margin-bottom: 16px;
}

// h6

@mixin t-heading--h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0px;
  margin-bottom: 16px;
}
