/* ==========================================================================
Components
  > Heroes
========================================================================== */

.c-hero {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - var(--header__height));
  min-height: calc(100dvh - var(--header__height));
  margin-top: -1px;

  &[data-article-hero__size='large'] {
    .has--alert & {
      min-height: calc(
        100dvh - var(--header__height) - var(--carouselAlertHeight) + 1px
      );
    }
  }

  // Content

  .c-hero__content {
    position: relative;
    z-index: 3;
    padding-block: var(--gutter);
    margin: auto 0;

    @include bp-xlarge {
      padding-block: var(--gutter--big);
    }

    .c-hero__content__item {
      @include t-banner--ui;
    }

    .c-hero__sub-wysiwyg {
      p {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  // Media

  .c-hero__media {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    img:-moz-loading {
      visibility: hidden;
    }
  }

  // Slideshow

  &__slideshow {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;

    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div,
    picture {
      height: 100%;
    }

    .slick-autoplay-toggle-button {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in-out 200ms;
      position: absolute;
      border-radius: 50%;
      background-color: #fff;
      opacity: 1;
      bottom: var(--gutter);
      left: var(--gutter);
      width: 32px;
      height: 32px;
      z-index: 2;
      padding: 0; // Safari UA

      span:before {
        font-size: 14px;
      }

      .slick-play-icon {
        transform: translateX(1px);
      }

      @include bp-small {
        width: var(--tap-target__size);
        height: var(--tap-target__size);

        span:before {
          font-size: 16px;
        }
      }

      @include bp-xlarge {
        bottom: 64px;
        left: 64px;
      }

      span {
        color: #000 !important;
      }

      &:hover {
        @include shadow(4);
      }
    }
  }

  &__subtext {
    * {
      margin-top: 24px;
    }
  }

  &__sub-wysiwyg {
    * {
      margin: 24px 0 0;
    }
  }

  &__logo {
    img {
      height: 150px;
    }
  }
}

/* ==========================================================================
Data attribute controls available:

  data-component__theme                 [light dark]
  data-article-hero__size               [mini medium large]
  data-article-hero__layout             [50-50 50-100 100 2cols]
  data-article-hero__protection--mobile [transparency--primary--max--ml]
  data-article-hero__protection         [full transparency gradient none]
  data-article-hero__text-alignment     [left centre]
  data-article-hero__art-direction      [true false | true]
  data-article-hero__img-position       [center top bottom top-right]
  data-article-hero__h1-size            [small]

========================================================================== */

/* ==========================================================================
  Size
========================================================================== */

// Mini

[data-article-hero__size='mini'] {
  min-height: 300px;

  @include bp-xxlarge {
    min-height: 350px;
  }
}
// Medium

[data-article-hero__size='medium'] {
  min-height: 0;
}

/* ==========================================================================
  Layout
========================================================================== */

// 50-50

[data-article-hero__layout='50-50'] {
  background-color: var(--color--primary);

  .c-hero__content,
  .c-hero__media {
    position: relative;
  }

  .c-hero__media {
    height: auto;
  }

  .c-hero__content {
    .c-hero__content__item {
      p {
        width: 100%;
        max-width: none;
      }
    }
  }

  @include bp-max-medium-large {
    height: auto;
    max-height: none;
  }

  @include bp-medium-large {
    flex-direction: row;

    .c-hero__content {
      display: flex;
      align-items: center;
      width: 50%;
    }

    .c-hero__media {
      position: relative;
      width: 50%;
    }
  }
}

// 50-100

@include bp-medium-large {
  [data-article-hero__layout='50-100'] {
    .c-hero__content {
      order: 1;
      width: 50%;
    }

    .c-hero__media {
      position: absolute;
      top: var(--header__height);
      z-index: 2;
      width: 100%;
      height: calc(100vh - var(--header__height));
    }
  }
}

// 2cols

[data-article-hero__layout='2cols'] {
  h1 {
    font-size: 40px;
  }
  .c-hero__content .c-hero__content__item p {
    font-size: 20px;
    width: auto;

    img {
      width: 200px;
      margin-right: 24px;
    }
  }
  .c-hero__content__item--right {
    @include bp-max-medium {
      margin-top: var(--gutter);
    }
    figure {
      max-height: 300px;
      margin-bottom: 0;
    }
  }

  @include bp-medium {
    .c-hero__content {
      display: flex;
    }
    .c-hero__content__item {
      width: 60%;
      padding-right: 10%;

      &--right {
        width: 40%;
      }
    }
  }
}

// 100

[data-article-hero__layout='100'] {
  flex-direction: column;
}

/* ==========================================================================
  Protection
========================================================================== */

// All

[data-article-hero__protection] {
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

// Full

[data-article-hero__protection='full'] {
  &:before {
    background: var(--color--primary--dark);
    opacity: 0.72;
  }
}

// Full - Light Theme

[data-component__theme='light'][data-article-hero__protection='full'] {
  &:before {
    background: var(--color--primary--lightest);
    background: #fff;
    opacity: 0.86;
  }
}

// Transparency

[data-article-hero__protection='transparency'] {
  .c-hero__content {
    padding: var(--gutter);
    background: var(--color--primary--transparency);
  }
}
[data-article-hero__protection='transparency'][data-component__theme='light'] {
  .c-hero__content {
    background: rgba(255, 255, 255, 0.86);
  }
}

// Transparency - Primary @ max ml

@include bp-max-medium-large {
  [data-article-hero__protection--mobile='transparency--primary--max--ml'] {
    .c-hero__content {
      background: var(--color--primary--transparency);
    }
  }
}

// Gradient

[data-article-hero__protection='gradient'] {
  &:before {
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 1) 80%
    );
  }
}
[data-article-hero__protection='gradient'][data-component__theme='dark'] {
  &:before {
    background: linear-gradient(
      to left,
      rgba(21, 38, 68, 0) 20%,
      rgba(21, 38, 68, 0.5) 40%,
      rgba(21, 38, 68, 1) 80%
    );
  }
}

// Dark

[data-component__theme='dark'] {
  .c-hero__media {
    background: var(--color--primary--dark);
  }
}

/* ==========================================================================
  H1 Size
========================================================================== */

// Small

[data-article-hero__h1-size='small'] {
  h1 {
    font-size: 44px;
  }
  .c-hero__content .c-hero__content__item {
    p {
      @include bp-full {
        font-size: 20px;
      }
    }
  }
}

/* ==========================================================================
  Text Alignment
========================================================================== */

// Left

[data-article-hero__text-alignment='left'] {
  text-align: left;
}

// Centre

[data-article-hero__text-alignment='centre'] {
  text-align: center;
  justify-content: center;
  align-items: center;

  .c-hero__content {
    margin-left: auto;
    margin-right: auto;
    bottom: auto;
    left: auto;

    .c-hero__content__item {
      h1 {
        @include bp-medium-large {
          max-width: unset;
        }
      }
      p {
        margin-left: auto;
        margin-right: auto;
      }

      .c-btn {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    @include bp-medium-large {
      margin: auto;
    }
  }
}

/* ==========================================================================
  Image Position
========================================================================== */

// Top

[data-article-hero__img-position='top'] {
  .c-hero__media {
    img {
      object-position: top;
    }
  }
}

// Bottom

[data-article-hero__img-position='bottom'] {
  .c-hero__media {
    img {
      object-position: bottom;
    }
  }
}
